import React from 'react';
import { PropTypes } from 'prop-types';
import { makeClassName } from 'utils';

import './typeahead_suggestions.sass';


/**
 * Used to show a list of clickable suggestions for the typeahead components
 * comboboxHandles and suggestions are handled by parent component
 */

export const TypeaheadSuggestions = (props) => {
  const cbh = props.comboboxHandlers;
  const suggestions = props.suggestions || [];

  return (
    <>
      <ul
        className="cr-typeahead-suggestion__listbox"
        {...cbh.listboxProps}
      >
        {suggestions.map((suggestion) => {
          const active = cbh.isActive(suggestion);
          return (
            <li
              key={suggestion[cbh.itemIdentityProperty]}
              {...cbh.itemProps(suggestion)}
              className={makeClassName(
                'cr-typeahead-suggestion__option',
                active && 'cr-typeahead-suggestion__option--active'
              )}
            >
              {props.showImageInResults &&
                <img src={suggestion.image_url} className="cr-typeahead-suggestion__option__image"/>
              }
              {props.renderer(suggestion, props)}
            </li>
          );
        })}
      </ul>
    </>
  );
};

TypeaheadSuggestions.propTypes = {
  comboboxHandlers: PropTypes.shape({
    expanded: PropTypes.bool,
    isActive: PropTypes.func,
    itemProps: PropTypes.func,
    listboxProps: PropTypes.object,
    itemIdentityProperty: PropTypes.string.isRequired
  }).isRequired,
  displayValueAttribute: PropTypes.string,
  showImageInResults: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  renderer: PropTypes.func
};

TypeaheadSuggestions.defaultProps = {
  displayValueAttribute: 'name',
  showImageInResults: false,
  renderer: (suggestion, { displayValueAttribute }) => (
    suggestion[displayValueAttribute]
  )
};
