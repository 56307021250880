import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Textarea } from "./textarea";
import { FormattedMessage } from "react-intl";

import './limit_textarea.sass';

export class LimitTextarea extends PureComponent {
  // This label supplement is the old version of the character count
  // TODO: Remove when every LimitTextArea uses the new "HighlightedTextarea" version
  composeLabelSupplement = () => {
    return (
      <FormattedMessage
        id="settings.profile.characters"
        defaultMessage=" ({value} characters)"
        values={{
          value: `${this.props.value ? this.props.value.length : "0"} / ${this.props.limit}`
        }}
      />);
  };

  render() {
    return (
      <Textarea
        {...this.props}
        maxLength={this.props.limit}
        labelSupplement={!this.props.highlightExcessCharacters && this.composeLabelSupplement()}
      />
    );
  }
}

LimitTextarea.propTypes = {
  ...Textarea.propTypes,
  limit: PropTypes.number.isRequired,
  highlightExcessCharacters: PropTypes.bool
};

LimitTextarea.defaultProps = {
  limit: 500
};
