import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'utils';
import { processedLocationData } from 'app_utils/location_data';
import { useStandardLocalMatches } from 'controls/search/search_hooks';
import { useEffectExceptOnMount } from 'utils/react_utils';
import { TypeaheadSelect } from 'form/typeahead_select.jsx';
import { Select } from './select';
import { HiddenField } from './hidden_field';

import { useIntl } from "react-intl";
import { intlKeyFromValue } from "translations";

export const StateOrProvinceTypeaheadSelectOrDropdown = props => {
  const doNotDiscloseFormattedMessage = useIntl().formatMessage({
    id: "settings.profile.do_not_disclose",
    defaultMessage: "Do not disclose"
  });

  const arrayOfStatesOrProvinces = React.useMemo(() => {
    const states = processedLocationData.getStatesOrProvinces(props.hostCountry);

    let statesToReturn = null;

    const doNotDiscloseOption = {
      selectionValue: '',
      displayValue: doNotDiscloseFormattedMessage
    };

    if (states) {
      statesToReturn = states;
      if (!props.hideDoNotDiscloseOption) {
        statesToReturn = [doNotDiscloseOption, ...statesToReturn];
      }
    }
    return statesToReturn;
  }, [props.hostCountry]);

  // Clear the field when the country changes.
  useEffectExceptOnMount(() => {
    props.handleChange(props.name, '');
  }, [props.hostCountry]);

  // Clear the field if there are no states.
  useEffect(() => {
    if (props.value && !arrayOfStatesOrProvinces) {
      props.handleChange(props.name, '');
    }
  }, [props.value, arrayOfStatesOrProvinces]);

  function GetPlaceholder() {
    const msg = props.hostCountry === 'Canada' ? 'Select Province' : 'Select State';
    return useIntl().formatMessage({
      id: intlKeyFromValue(msg, "settings.profile"),
      defaultMessage: msg
    });
  }

  if (arrayOfStatesOrProvinces && props.typeaheadSelect) {
    // If we integrate data from wanted_subdivisions.yml, we can make this less hacky.
    return (
      <StateOrProvinceTypeaheadSelectResource
        {...props}
        localMatches={arrayOfStatesOrProvinces}
      />
    );
  } else if (!arrayOfStatesOrProvinces) {
    // Always return a field, or the value won't be updated when there are no states.
    return (<HiddenField {...props} />);
  } else {
    // If no dropdown prop specified, default to typeahead select
    return (
      <StateOrProvinceDropdownResource
        {...props}
        placeholder={GetPlaceholder()}
        options={arrayOfStatesOrProvinces}
      />
    );
  }
};

const StateOrProvinceDropdownResource = (props) => {
  return <Select {...props} placeholder={props.placeholder} options={props.options} />;
};

const StateOrProvinceTypeaheadSelectResource = (props) => {
  const handleChange = useCallback((name, value) => {
    props.handleChange(name, value?.displayValue);
  }, [props.handleChange]);

  const localMatches = useStandardLocalMatches(props.localMatches, 'displayValue', true);
  const idPrefix = (i) => `stateOrProvince_resource_${i}`;
  const [id, setId] = useState(
    idPrefix(uniqueId())
  );

  const [showInitialValue, setInitialValue] = useState(props.initialValue || '');

  useEffect(() => {
    // Force clear typeahead if hostCountry changes
    setId(idPrefix(uniqueId()));
    const match = props.localMatches.find(match => match.displayValue === props.initialValue);
    if (!match) {
      setInitialValue('');
    }
  }, [props.hostCountry]);

  return (
    <TypeaheadSelect
      {...props}
      key={id}
      initialValue={showInitialValue}
      handleChange={handleChange}
      localMatches={localMatches}
      displayValueAttribute="displayValue"
    />
  );
};

StateOrProvinceTypeaheadSelectOrDropdown.propTypes = {
  ...TypeaheadSelect.propTypes,
  ...Select.propTypes,
  hideDoNotDiscloseOption: PropTypes.bool,
  hostCountry: PropTypes.string,
  localMatches: PropTypes.arrayOf(
    PropTypes.shape({
      displayValue: PropTypes.string.isRequired,
      selectionValue: PropTypes.string.isRequired
    })
  ),
  handleChange: PropTypes.func
};

StateOrProvinceDropdownResource.defaultProps = {
  label: 'State/Province'
};

StateOrProvinceDropdownResource.propTypes = {
  ...Select.propTypes
};

StateOrProvinceTypeaheadSelectResource.propTypes = {
  ...TypeaheadSelect.propTypes,
  localMatches: PropTypes.arrayOf(
    PropTypes.shape({
      displayValue: PropTypes.string.isRequired,
      selectionValue: PropTypes.string.isRequired
    })
  ).isRequired,
  initialValue: PropTypes.string,
  hostCountry: PropTypes.string
};


export const testing = { StateOrProvinceTypeaheadSelectResource, StateOrProvinceDropdownResource };
