import React, { useCallback, useRef, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { uniqueId } from 'utils';
import * as objectUtils from 'utils/object';
import { useSearchOnType } from 'controls/search/search_hooks';
import { useComboboxHandlers } from 'form/form_hooks';
import TextField from './text_field';
import FieldGroup from './field_group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { TypeaheadSuggestions } from "./typeahead_suggestions";

import './typeahead_select.sass';

/**
 * The TypeaheadSelect is similar to the TypeaheadTextField but it does not allow free form input.
 * A value must be selected from the list.
 */

export const TypeaheadSelect = (props) => {
  const [inputValue, setInputValue] = useState(props.initialValue || '');
  const [selectedItem, setSelectedItem] = useState();

  const id = useRef(null);
  if (id.current === null) {
    id.current = uniqueId();
  }
  const [expanded, setExpanded] = useState(false);
  const searchState = useSearchOnType(
    props.searchActionState,
    props.searchAction,
    inputValue,
    { localMatches: props.localMatches, active: expanded }
  );

  const textFieldProps = objectUtils.except(props,
    [
      'displayValueAttribute',
      'itemLabel',
      'itemLabelPlural',
      'localMatches',
      'searchAction',
      'searchActionState',
      'showImageInResults',
      'tagUi',
      'value'
    ]
  );

  // We need to pass a ref to the TextField so that we can focus and blur it
  const typeaheadInputRef = useRef();

  const onSelect = useCallback((item) => {
    if (!item) {
      return;
    }
    setSelectedItem(item);
    props.handleChange(props.name, item);
  }, [setSelectedItem, props.handleChange]);

  const comboboxHandlers = useComboboxHandlers(
    searchState.results,
    onSelect,
    props.displayValueAttribute
  );

  useEffect(() => { setExpanded(comboboxHandlers.expanded); }, [comboboxHandlers.expanded]);

  const handleFocus = useCallback(() => {
    props.handleFocus && props.handleFocus();
    comboboxHandlers.handleFocus();
  }, [props.handleFocus, comboboxHandlers.handleFocus]);

  const handleBlur = useCallback((event) => {
    props.handleBlur && props.handleBlur();
    comboboxHandlers.handleBlur(event, () => setInputValue(''));
  }, [props.handleBlur, comboboxHandlers.handleBlur, selectedItem]);

  let suggestions = null;

  if (searchState.results?.length > 0 && comboboxHandlers.expanded) {
    suggestions = <TypeaheadSuggestions
      key={props.key}
      comboboxHandlers={comboboxHandlers}
      showImageInResults={props.showImageInResults}
      suggestions={searchState.results}
      displayValueAttribute={props.displayValueAttribute}
      renderer={props.suggestionRenderer}
    />;
  }

  const handleTextFieldChange = (name, value) => {
    // When the user types in the text field, they need to select their option again
    setSelectedItem(null);
    setInputValue(value);
    props.handleChange(name, null);
  };

  let tfInputValue = inputValue;

  if (selectedItem) {
    tfInputValue = selectedItem[props.displayValueAttribute];
  }

  return (
    <div
      className="cr-typeahead-select"
      {...comboboxHandlers.comboboxProps}
      tabIndex={-1}
    >
      <TextField
        {...textFieldProps}
        handleChange={handleTextFieldChange}
        value={tfInputValue}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        onKeyDown={comboboxHandlers.handleKeyDown}
        showIcon
        inputRef={typeaheadInputRef}
        {...comboboxHandlers.textboxProps}
        autoComplete="off"
      />
      <FontAwesomeIcon icon={faSearch} className="cr-typeahead__icon"/>
      {suggestions}
    </div>
  );
};

// This takes in all of FieldGroup.propTypes, but the main ones developers should include are:
// value: PropTypes.string
// label: PropTypes.string
// handleChange: PropTypes.func
// enclosed: PropTypes.boolean (For styling)
TypeaheadSelect.propTypes = {
  displayValueAttribute: PropTypes.string,
  iconStyle: PropTypes.oneOf(['arrows', 'none', 'search']),
  localMatches: PropTypes.func,
  searchAction: PropTypes.func,
  searchActionState: PropTypes.object,
  suggestionRenderer: PropTypes.func,
  key: PropTypes.string,
  ...FieldGroup.propTypes
};

TypeaheadSelect.defaultProps = {
  displayValueAttribute: 'name'
};
