import React, { Component } from "react";
import PropTypes from "prop-types";
import debounce from 'lodash.debounce';
import * as objUtils from 'utils/object';
import { ImmediateProperty } from 'utils/react_utils';
import { useLocalForm } from 'form/use_local_form';
import { FormattedMessage } from "react-intl";
import { intlKeyFromValue } from "translations";

import "./form.sass";

const SUBMIT_DEBOUNCE = 300;
const WAIT_FOR_AUTOFILL = 500;


/**
 * This component creates an HTML form. It supports both redux-connected and non-connected forms.
 *
 * Connected: <Form/>
 * Non-connected: <LocalForm/>
 *
 * Forms are connected using the connectForm() function.
 *
 * @property {function({propsForFieldGroup:function(string), isSubmitting: boolean})} children -
 *   The single child of this component is a function which takes an object as a parameter, and
 *   returns a React.element. The object includes:
 *   - propsForFieldGroup: A function which takes a field name as a parameter, and returns generated
 *     properties for the named field.
 *   - isSubmitting: True if the form is currently submitting.
 *   - submit: A function that will submit the form. Useful for implementations where more control
 *     is desired on when to submit a form.
 *   - touched: An object in which its keys represent fields that have been modified in the form
 * @property {String} className - CSS classes to add to the component.
 * @property {object} initialValues: The initial state of the form. This may include hidden fields
 *   as well, which do not appear in the form. When using a connected form, this will default to
 *   the redux resource.
 * @property {object} staticParams: Static additional parameters to send when submitting the form
 * e.g. `organization_id`
 * @property {function(object)} onSubmitSuccess: Called on successful submit. It takes the set of
 *   submitted values as a parameter.
 * @property {function({globalErrors: array<string>, fieldErrors: array<string>})} onSubmitFailure:
 *   Called on a failed submit. globalErrors is a list of of errors not attached to any field.
 *   fieldErrors is a list of errors attached to fields.
 * @property {Boolean} submitOnChange: Submit the form every time any field changes. This is
 *   throttled and debounced.
 * @property {ImmediateProperty} resetNow: Update this to reset the form.
 * @property {Boolean} noInlineErrors - Don't show errors for each field. Instead, display all
 *   errors at the top of the form.
 * @property {Boolean} singleUse: Normally, the form clears on a successful submit, so it can be
 *   used again. Enable this flag to prevent that behavior.
 *
 * When using LocalForm, you might also want to use these properties:
 * @property {object<array>} errors: A list of errors, mapped from field name to an array of
 *   error strings. Errors will be rendered above the offending fields. Errors that don't match any
 *   field will be rendered at the top of the form.
 * @property {function(object)} submit: Called when the form is ready to submit. The parameter is
 *   a map of url names to url values. If names are of the form "a[b]," they will be automatically
 *   transformed into a nested object.
 */
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claimedFields: {},
      hasSubmitted: false,
      touched: {},
      values: { ...props.initialValues }
    };
    this.m_justMounted = false;
    this.m_validators = {};
  }

  componentDidMount() {
    this.m_justMounted = true;
    setTimeout(() => {
      this.m_justMounted = false;
    }, WAIT_FOR_AUTOFILL);
  }


  /**
   * Called after render() due to properties or state change.
   *
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    const {
      initialValues,
      submitStatus,
      addAlert,
      submitSuccessAlert,
      submitFailureAlert,
      onSubmitSuccess,
      onSubmitFailure,
      resetNow,
      resetOnInitialValuesChange
    } = this.props;

    // Optionally allow `initialValues` changes to reset the form values
    if (resetOnInitialValuesChange && !objUtils.shallowEquals(prevProps.initialValues, initialValues)) {
      this.setState({
        values: initialValues
      });
    }

    // Reset the form after a successful submit.
    if (!prevProps.submitStatus.succeeded && submitStatus.succeeded && this.state.hasSubmitted) {
      submitSuccessAlert && addAlert(submitSuccessAlert);
      onSubmitSuccess && onSubmitSuccess(this.state.values);
      if (!this.props.singleUse) {
        this.setState({
          hasSubmitted: false,
          values: initialValues || {}
        });
      }
    } else if (!prevProps.submitStatus.failed && submitStatus.failed && this.state.hasSubmitted) {
      submitFailureAlert && addAlert(submitFailureAlert);

      const unclaimed = [];
      const claimed = [];
      objUtils.forEach(this.props.errors, (name, error) => {
        (this.state.claimedFields[name] ? claimed : unclaimed).push({ [name]: error });
      });

      onSubmitFailure && onSubmitFailure({
        globalErrors: unclaimed,
        fieldErrors: claimed,
        clearErrors: this.clearErrors,
        addValues: this.addValues,
        removeValues: this.removeValues
      });
    }

    if (resetNow && resetNow.changed(prevProps.resetNow)) {
      // Reset the form.
      this.setState({
        values: initialValues || {}
      });
    }
  }

  /**
   * This function adds a field_group's name to an object which the
   * unclaimedErrors() fn later queries, when assigning errors
   * returned from the server.
   *
   * @param {String} name
   * @param {function(string):bool} validator - Optional client-side validation.
   */
  claimField = (name, validator) => {
    if (validator) {
      this.m_validators[name] = validator;
    }

    if (!this.props.noInlineErrors) {
      this.setState(state => {
        if (!state.claimedFields[name]) {
          return {
            ...state,
            claimedFields: { ...state.claimedFields, [name]: true }
          };
        } else {
          return null;
        }
      });
    }
  };

  /**
   * Get the errors for a field.
   *
   * @param {String} name
   * @returns {Object|undefined}
   */
  errorsFor(name) {
    if (!this.props.noInlineErrors) {
      if (this.state.hasSubmitted && !this.state.touched[name]) {
        return this.props.errors[name];
      }
    }
  }

  /**
   * Clear some or all errors. This is exposed through the props.children function.
   *
   * @param {String|Array} [fieldNames] - Field names to clear, or omit to clear all errors.
   */
  clearErrors = (fieldNames = null) => {
    if (fieldNames === null) {
      this.setState({ touched: {} });
    } else {
      this.setState((currentState) => {
        const updatedState = currentState;

        if (fieldNames instanceof Array) {
          fieldNames.forEach(name => delete updatedState.touched[name]);
        } else {
          delete updatedState.touched[fieldNames];
        }

        return updatedState;
      });
    }

    this.props.clearClientErrors(fieldNames);
  };

  /**
   * Add new values to the state. This is required when new fields are created in an existing form.
   * Without it, the new data will only be submitted if the user changes the value.
   *
   * @param {object<string, *>} values
   * @param {function} callback - Called when the operation is complete.
   */
  addValues = (values, callback) => {
    this.setState({ values: { ...this.state.values, ...values } }, callback);
  };

  /**
   * Remove values from the state. This is required when new fields are created in an existing form.
   * Without it, the new data will only be submitted if the user changes the value.
   *
   * @param {object<string, *>} values - A set of fields to remove (the values of the object are
   *   irrelevant).
   * @param {function} callback - Called when the operation is complete.
   */
  removeValues = (values, callback) => {
    if (Object.keys(values).length === 0) {
      callback();
    } else {
      this.setState(
        { values: objUtils.filter(this.state.values, key => !(key in values)) },
        callback
      );
    }
  };

  /**
   * Make an API request on form submit.
   *
   * @param {Event} e
   */
  handleSubmit = e => {
    e && e.preventDefault();
    this.setState({ hasSubmitted: true });

    const errors = {};
    for (const name of Object.keys(this.m_validators)) {
      const validate = this.m_validators[name];
      const result = validate(name, this.state.values[name]);
      if (result.length > 0) {
        errors[name] = result;
      }
    }

    if (Object.keys(errors).length > 0) {
      // newTouched is a copy of touched that excludes all new client side validation errors
      const newTouched = objUtils.except(this.state.touched, Object.keys(errors));
      this.setState({ touched: newTouched });
      // Use redux to merge client errors into existing errors.
      this.props.addClientErrors(errors);
    } else {
      this.setState({ touched: {} });

      const submitValues = { ...this.props.staticParams, ...this.state.values };
      this.props.submit && this.props.submit(submitValues);
    }
  };

  /**
   * A field has lost focus.
   *
   * @param {Event} e
   */
  onBlur = e => {
    // no-op currently
  };

  /**
   * A field's content has changed due to user interaction.
   *
   * @param {String} name
   * @param {String|Function} value
   */
  onChange = (name, value) => {
    this.setState(state => {
      if (typeof value === 'function') {
        value = value(state.values[name]);
        if (value === undefined) {
          value = state.values[name];
        }
      }
      if (state.values[name] !== value) {
        const newState = {
          values: { ...state.values, [name]: value }
        };

        // Try to avoid changes due to autofill.
        if (!this.m_justMounted) {
          newState.touched = { ...state.touched, [name]: true };
        }

        return newState;
      }
    }, this.props.submitOnChange && this.submitDebounced);
  };

  /**
   * Called when anything changes, if props.submitOnChange.
   */
  submitDebounced = debounce(this.handleSubmit, SUBMIT_DEBOUNCE, { maxWait: 10000 });

  /**
   * A field has gained focus.
   *
   * @param {Event} e
   */
  onFocus = e => {
    // no-op currently
  };

  /**
   * Add calculable properties to a <FieldGroup>
   * TODO: is it helpful to memoize this so that it only changes if props or state that it
   *       uses changes?  Or is that pointless, since typical usage would be, e.g.
   *       <Component {...props.propsForFieldGroup()}/>
   *       ---
   *       The answer to this old question is no, because:
   *       - The function pointers don't change.
   *       - Scalars (name and value) aren't compared as pointers.
   *       - errorsFor() returns existing pointers, so these also don't change unless
   *       - the specific error changes.
   *       So memoizing will not improve React performance. It may slightly improve JS performance,
   *       but at the cost of a lot of extra, potentially buggy, code in React lifecycle events.
   *
   * @param {String} name
   * @returns {Object}
   */
  propsForFieldGroup = name => {
    return {
      claimField: this.claimField,
      releaseField: this.releaseField,
      handleBlur: this.onBlur,
      handleFocus: this.onFocus,
      handleChange: this.onChange,
      name,
      value: this.state.values[name],
      errors: this.errorsFor(name)
    };
  };

  /**
   * This function removes a field_group's name from an object which the
   * unclaimedErrors() fn later queries, when assigning errors
   * returned from the server.
   *
   * @param {String} name
   */
  releaseField = name => {
    delete this.m_validators[name];

    this.setState(state => {
      if (state.claimedFields[name]) {
        return {
          ...state,
          claimedFields: { ...state.claimedFields, [name]: false }
        };
      } else {
        return null;
      }
    });
  };

  /**
   * This function checks for any errors returned from the server which are
   * not associated with a given form field-group.
   *
   * @returns {Array}
   */
  unclaimedErrors() {
    const result = [];
    const errors = this.props.errors;
    for (const name in errors) {
      if (
        errors.hasOwnProperty(name) &&
        !this.state.claimedFields[name] &&
        errors[name]
      ) {
        result.push([name, errors[name]]);
      }
    }
    return result;
  }

  /**
   * Prevent the browser from rendering its own errors on failed input-type validation. Instead,
   * submit the form to generate client-side errors.
   *
   * @param {Event} e
   */
  onInvalid = e => {
    e.preventDefault();
    e.stopPropagation();
    this.handleSubmit();
  };

  /**
   * Render the component.
   *
   * @returns {*}
   */
  render() {
    const props = this.props;

    // After internationalizing the Share Email component by injectIntl, it returns
    // an array whose second item is the right Component that Form needs.
    // This is the easiest way I found to do this.
    const children = Array.isArray(props.children) ? props.children[1] : props.children;

    return (
      <form
        onSubmit={this.handleSubmit}
        className={this.props.className}
        onInvalid={this.onInvalid}
      >
        {
          this.state.hasSubmitted &&
            <div className="c-form__unclaimed-errors-wrapper">
              {this.unclaimedErrors().map(([name, errors]) => {
                return (
                  <ul className="c-form__unclaimed-errors" key={name}>
                    {errors.map((error, index) => (
                      <li className="c-form__unclaimed-error" key={index}>
                        <FormattedMessage
                          id={intlKeyFromValue(error, "form_error_message")}
                          defaultMessage={error}
                        />
                      </li>
                    ))}
                  </ul>
                );
              })}
            </div>
        }
        {children({
          propsForFieldGroup: this.propsForFieldGroup,
          isSubmitting: this.props.submitStatus.pending,
          submit: this.handleSubmit,
          clearErrors: this.clearErrors,
          addValues: this.addValues,
          removeValues: this.removeValues,
          touched: this.state.touched
        })}
      </form>
    );
  }
}


/**
 * Non-redux-connected form. This is equivalent to using the useFormProps hook with no action.
 *
 * @param {Object} props - Properties to pass to <Form>.
 *   @param {function(values: object)} props.submit - Called when the form is submitted, with
 *     the form fields as key/value pairs. If this returns false, submit is assumed to have failed.
 */
export const LocalForm = props => <Form {...useLocalForm(props)} />;

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  className: PropTypes.string,
  errors: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitFailure: PropTypes.func,
  submit: PropTypes.func,
  addClientErrors: PropTypes.func,
  clearClientErrors: PropTypes.func,
  submitOnChange: PropTypes.bool, // Submit the form every time a field changes.
  resetNow: PropTypes.instanceOf(ImmediateProperty),
  resetOnInitialValuesChange: PropTypes.bool,
  noInlineErrors: PropTypes.bool,
  submitStatus: PropTypes.shape({
    failed: PropTypes.bool,
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool
  }),
  singleUse: PropTypes.bool
};

Form.defaultProps = {
  errors: {},
  submitStatus: {
    failed: false,
    idle: true,
    pending: false,
    succeeded: false
  },
  addClientErrors: () => null,
  clearClientErrors: () => null
};

LocalForm.propTypes = Form.propTypes;

export const testing = { SUBMIT_DEBOUNCE, WAIT_FOR_AUTOFILL };
