import { useCallback, useRef, useState, useEffect } from 'react';
import { uniqueId } from 'utils';
import { useImmediateEffect, useOnOff, useShallowEquals } from 'utils/react_utils';
import element from 'utils/element';

export const useComboboxHandlers = (data, onSelect, itemIdentityProperty = 'id') => {
  const dataMemo = useShallowEquals(data);
  const itemIdsDataRef = useRef(dataMemo);
  const itemIds = useRef({});
  const itemsById = useRef({});
  useImmediateEffect(() => {
    itemIdsDataRef.current = dataMemo;
    itemIds.current = {};
    itemsById.current = {};
  }, [dataMemo]);
  const listBoxId = useRef();
  if (!listBoxId.current) {
    listBoxId.current = `listbox_${uniqueId()}`;
  }
  const textBoxId = useRef();
  if (!textBoxId.current) {
    textBoxId.current = `textbox_${uniqueId()}`;
  }
  const [activeItem, setActiveItem] = useState(null);

  const isActive = (suggestion) => (suggestion === activeItem);

  const getItemId = useCallback((datum) => {
    let result = itemIds.current[datum[itemIdentityProperty]];
    if (!result) {
      result = itemIds.current[datum[itemIdentityProperty]] = uniqueId();
      itemsById.current[`listbox_item_${result}`] = datum;
    }
    return `listbox_item_${result}`;
  }, [dataMemo]);

  const activeDescendantId = activeItem && getItemId(activeItem);

  const comboboxRef = useRef();
  const [expanded, setExpandedOn, setExpandedOff] = useOnOff(false);

  const handleFocus = useCallback(() => {
    setExpandedOn();
  }, []);

  const handleBlur = useCallback((event, onComboboxBlur) => {
    if (event && comboboxRef.current.contains(event.relatedTarget)) {
      event.preventDefault();
    } else {
      setExpandedOff();
      setActiveItem(null);
      onComboboxBlur && onComboboxBlur();
    }
  }, []);

  // When keyboard input is used to scroll through a list view, we need to make sure that the
  // active list item is visible to the user
  useEffect(() => {
    if (activeItem) {
      const el = document.getElementById(getItemId(activeItem));
      if (el && (!element.isElementFullyVisibleInParent(el) || !element.isVisible(el))) {
        el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  });

  // wrap calls to onSelect in useEffect to ensure component renders first with current state.
  // onSelect callback may cause the entire component to rerender with new data (ex: form/typeahead_multi_select.jsx)
  // which confuses the screen reader when aria attributes are not rendered using original state first.
  const [selectedItem, selectItem] = useState(false);
  useEffect(() => {
    onSelect(selectedItem);
    setExpandedOff();
    setActiveItem(null);
  }, [selectedItem]);

  const handleKeyDown = useCallback((evt) => {
    const key = evt.key;
    if (key !== 'Escape') {
      handleFocus();
    }
    if (key === 'ArrowDown') {
      evt.preventDefault();
      if (activeItem) {
        let curIndex = dataMemo.indexOf(activeItem);
        if (curIndex === dataMemo.length - 1) {
          curIndex = -1;
        }
        setActiveItem(dataMemo[curIndex + 1]);
      } else if (dataMemo.length > 0) {
        setActiveItem(dataMemo[0]);
      }
    } else if (key === 'ArrowUp') {
      evt.preventDefault();
      if (activeItem) {
        const curIndex = dataMemo.indexOf(activeItem) || dataMemo.length;
        setActiveItem(dataMemo[curIndex - 1]);
      } else if (dataMemo.length > 0) {
        setActiveItem(dataMemo[dataMemo.length - 1]);
      }
    } else if (key === 'Enter') {
      evt.preventDefault();
      if (activeItem && itemIdentityProperty === 'displayValue') {
        skipUseEffectOnTypeaheadSelectOrDropdown(activeItem);
      } else if (activeItem) {
        selectItem(activeItem);
      } else if (dataMemo.length > 0) {
        setActiveItem(dataMemo[0]);
      }
    } else if (key === 'Escape') {
      selectItem(null);
    }
  }, [activeItem, expanded, dataMemo, onSelect, handleFocus]);
  const handleOptionClick = useCallback((event) => {
    const item = itemsById.current[event.currentTarget.id];
    if (item && itemIdentityProperty === 'displayValue') {
      skipUseEffectOnTypeaheadSelectOrDropdown(item);
    } else if (item) {
      setActiveItem(item);
      selectItem(item);
    }
  }, [onSelect]);

  const comboboxProps = {
    "aria-haspopup": 'listbox',
    "aria-owns": listBoxId.current,
    // TODO: fix this
    "aria-expanded": expanded && data.length > 0,
    role: 'combobox',
    ref: comboboxRef
  };

  const textboxProps = {
    id: textBoxId.current,
    "aria-autocomplete": 'list',
    "aria-controls": listBoxId.current,
    "aria-activedescendant": activeDescendantId
  };

  const listboxProps = {
    role: 'listbox',
    id: listBoxId.current
  };
  // skip useEffect with Country and State TypeaheadSelectDropdowns as the delay
  // causes inputting the previous selection after backspace not to take effect
  const skipUseEffectOnTypeaheadSelectOrDropdown = (item) => {
    onSelect(item);
    setExpandedOff();
    setActiveItem(null);
  };

  const itemProps = (datum) => {
    const itemId = getItemId(datum);
    return {
      id: itemId,
      role: 'option',
      "aria-selected": isActive(datum),
      onClick: handleOptionClick,
      tabIndex: -1
    };
  };

  return {
    handleBlur,
    handleFocus,
    handleKeyDown,
    activeItem,
    activeDescendantId,
    itemIdentityProperty,
    getItemId,
    expanded,
    comboboxProps,
    textboxProps,
    listboxProps,
    setExpandedOff,
    isActive,
    itemProps
  };
};
