import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FieldGroup from "./field_group";
import { textFieldTypes } from './utils';
import { Validators } from 'utils/validators';

import "./text_field.sass";

export default class TextField extends PureComponent {
  constructor(props) {
    super(props);
    this.m_input = props.inputRef || React.createRef();
  }

  cancelEvent = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  onClick = () => {
    this.m_input.current.focus();
  };

  validate = (name, val) => {
    const type = this.props.type;
    const max = this.props.inputMax;
    const isCurrency = this.props.isCurrency;
    const formattedMax = Intl.NumberFormat(
      'en-US', { style: 'currency', currency: 'USD' })
      .format(max);

    if (val) {
      // These validators are not exactly the same as the ones we use in Ruby, but that's okay.
      // It just means that if these fail, we'll fall through to server-side validation.
      if (type === 'url') {
        if (!Validators.url(val)) {
          return ['is not a valid URL'];
        }
      } else if (type === 'email') {
        if (!Validators.email(val)) {
          return ['is not a valid email address'];
        }
      } else if (type === 'number') {
        if (max && isCurrency) {
          if (val >= max) {
            return ['cannot be greater than ' + formattedMax];
          }
        } else if (max) {
          if (val >= max) {
            return ['cannot be greater than ' + max];
          }
        }
      }
    }

    // Check other validators
    return this.props.validate ? this.props.validate(name, val) : [];
  };

  render() {
    const { inputPrefix, ...propsSansInputPrefix } = this.props;
    return (
      <FieldGroup
        {...propsSansInputPrefix}
        validate={this.validate}
        render={({ required, ...otherProps }) => {
          if (inputPrefix) {
            return (
              <div
                className="c-text-field__input-wrapper"
                onClick={this.onClick}
              >
                <span className="c-text-field__input-prefix">
                  {inputPrefix}
                </span>
                <input
                  type={this.props.type || 'text'}
                  {...otherProps}
                  ref={this.m_input}
                  aria-required={required}
                  className="c-text-field__input c-text-field__input--prefixed"
                  onInvalid={this.cancelEvent}
                />
              </div>
            );
          } else {
            return (
              <input
                type={this.props.type || 'text'}
                {...otherProps}
                ref={this.m_input}
                aria-required={required}
                className="c-text-field__input"
              />
            );
          }
        }}
      />
    );
  }
}

TextField.propTypes = {
  /**
   * Props from FieldGroup
   */
  ...FieldGroup.propTypes,
  /**
   * Prefix for string
   */
  inputPrefix: PropTypes.string,
  /**
   * Type of textfield
   */
  type: PropTypes.oneOf(textFieldTypes)
};
