import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { uniqueId } from 'utils';
import { pluralize } from 'utils/localization';
import { useSearchOnType } from 'controls/search/search_hooks';
import { useComboboxHandlers } from 'form/form_hooks';
import TextField from './text_field';
import FieldGroup from './field_group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import * as objectUtils from 'utils/object';
import { Tag, TagList, TagIconButton, TAG_UIS } from "controls/tags";
import { TypeaheadSuggestions } from "./typeahead_suggestions";

import './typeahead_multi_select.sass';

/**
 *
 * Usage of this component is as follows:
 *
 * const [searchState, searchPerform] = myCustomAction.useAction('fetch');
 *
 * const searchAction = useCallback((query) => {
 *    return searchPerform({ query });
 * }, [*any needed dependencies, usually some form of props*]);
 *
 * <TypeaheadMultiSelect
 *   searchAction={searchAction}
 *   searchActionState={searchState}
 *   enclosed
 *   {...propsForFieldGroup('field_name')}
 *   label={*The text to show in the box, usually the field name*}
 * />
 *
 * Note that you can also use `props.localMatches` instead of all the `searchAction` stuff if the
 * parent component has already loaded the typeahead options.
 *
 * @param {object} props
 * @param {string} [props.displayValueAttribute='name'] - the attribute of search suggestions and
 *   selections that should be used to render a human-readable value for the item
 * @param {string[]} [props.errors=[]] - (normally from propsForFieldGroup) error messages
 *   associated with the field
 * @param {function(string, function(object[]))} props.handleChange -  (normally from
 *   propsForFieldGroup) a function to update the list of selected values with additions or
 *   removals; the function is called using the callback form of handleChange, so that the updates
 *   applied are guaranteed to be consistent with the latest state of the form value (note that
 *   a setter as from useState will be compatible)
 * @param {string} props.label - the label for this field; will be displayed on the typeahead text
 *   input, since that is the first focusable element of the field and where error messages are
 *   attached
 * @param {string} props.name - (normally from propsForFieldGroup) the name of the field, which -
 *   when the field is used within a form - will be the key under which the value will be stored
 *   within the form data
 * @param {function(string)} props.searchAction - function to trigger a new search request for
 *   suggestions based on updates to the text input
 * @param {ActionState} props.searchActionState - current results of search for suggestions
 * @param {string} [props.tagUi='standard'] - the appearance to use for tags showing selected values
 *   (see {@link Tag} for accepted values)
 * @param {object[]} props.value - (normally from propsForFieldGroup) - a list of currently-
 *   selected values; the elements should have at least id and [displayValueAttribute] attributes
 * @param {function(object)} props.valueCallback - used for passing the current list of values to parent components
 */

export const TypeaheadMultiSelect = (props) => {
  const [inputValue, setInputValue] = useState('');
  const id = useRef(null);
  if (id.current === null) {
    id.current = uniqueId();
  }
  const [expanded, setExpanded] = useState(false);
  const searchState = useSearchOnType(
    props.searchActionState,
    props.searchAction,
    inputValue,
    { localMatches: props.localMatches, active: expanded }
  );

  const textFieldProps = objectUtils.except(props,
    [
      'displayValueAttribute',
      'itemLabel',
      'itemLabelPlural',
      'localMatches',
      'searchAction',
      'searchActionState',
      'showImageInResults',
      'tagUi',
      'value'
    ]
  );

  // We need to pass a ref to the TextField so that we can focus and blur it
  const typeaheadInputRef = useRef();

  // When an option is selected, we will empty out the text field, and add the item to our list
  const onSelect = (item) => {
    if (!item) {
      return;
    }
    props.handleChange(props.name, (previousValue) => {
      if (!previousValue) {
        previousValue = [];
      }
      if (!previousValue.find((e) => e.id === item.id)) {
        return [...previousValue, item];
      }
    });
  };

  // When an option is removed
  const removeTag = (tag) => {
    props.handleChange(props.name, (previousValue) => {
      const valueAt = previousValue.findIndex((e) => e.id === tag.id);
      if (valueAt >= 0) {
        const newValue = previousValue.slice();
        newValue.splice(valueAt, 1);
        return newValue;
      }
    });
  };

  const filteredSuggestions = useMemo(() => {
    return searchState.results.filter((e) => !props.value?.find((e1) => e1.id === e.id));
  }, [searchState.results, props.value]);

  const comboboxHandlers = useComboboxHandlers(
    filteredSuggestions,
    onSelect,
    'id'
  );

  useEffect(() => { setExpanded(comboboxHandlers.expanded); }, [comboboxHandlers.expanded]);

  const handleFocus = useCallback(() => {
    props.handleFocus && props.handleFocus();
    comboboxHandlers.handleFocus();
  }, [props.handleFocus, comboboxHandlers.handleFocus]);

  const handleBlur = useCallback((event) => {
    props.handleBlur && props.handleBlur();
    comboboxHandlers.handleBlur(event);
    setInputValue('');
  }, [props.handleBlur, comboboxHandlers.handleBlur]);

  let suggestions = null;

  if (filteredSuggestions.length > 0 && comboboxHandlers.expanded) {
    suggestions = <TypeaheadSuggestions
      comboboxHandlers={comboboxHandlers}
      showImageInResults={props.showImageInResults}
      suggestions={filteredSuggestions}
      displayValueAttribute={props.displayValueAttribute}
      {...props}
    />;
  }

  if (props.valueCallback) {
    props.valueCallback(props.value);
  }

  let selectedTagsEl = null;
  if (props.value?.length > 0) {
    selectedTagsEl = (
      <TagList>
        {props.value?.map((tag) => {
          const tagDisplayName = tag[props.displayValueAttribute];
          return (
            <Tag
              key={tag.id}
              ui={props.tagUi || 'standard'}
              selected
              icon={
                <TagIconButton
                  aria-label={`Remove ${tagDisplayName}`}
                  icon={faTimes}
                  onClick={() => removeTag(tag)}
                />
              }
            >
              {tagDisplayName}
            </Tag>
          );
        })}
      </TagList>
    );
  }

  return (
    <div className="cr-typeahead-multi-select">
      <div
        className="cr-typeahead-multi-select__input-group"
        {...comboboxHandlers.comboboxProps}
        tabIndex={-1}
      >
        <TextField
          {...textFieldProps}
          handleChange={(_name, value) => setInputValue(value)}
          value={inputValue}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          onKeyDown={comboboxHandlers.handleKeyDown}
          showIcon
          inputRef={typeaheadInputRef}
          {...comboboxHandlers.textboxProps}
          autoComplete="off"
          aria-describedby={`typeahead_multi_select_selected${id.current}`}
        />
        {!props.hideSearchIcon && <FontAwesomeIcon icon={faSearch} className="cr-typeahead__icon"/>}
        {suggestions}
        <div
          className="cr-typeahead-multi-select__selected-hint"
          id={`typeahead_multi_select_selected${id.current}`}
        >
          {pluralize(
            props.value?.length || 0,
            props.itemLabel,
            props.itemLabelPlural
          )} selected
        </div>
      </div>
      {selectedTagsEl}
    </div>
  );
};

TypeaheadMultiSelect.propTypes = {
  itemLabel: PropTypes.string,
  itemLabelPlural: PropTypes.string,
  localMatches: PropTypes.func, // Used when there are preloaded typeahead dropdown options
  searchAction: PropTypes.func,
  searchActionState: PropTypes.object,
  showImageInResults: PropTypes.bool,
  displayValueAttribute: PropTypes.string, // display value attribute for the typeahead and tags
  tagUi: PropTypes.oneOf(TAG_UIS),
  hideSearchIcon: PropTypes.bool,
  ...FieldGroup.propTypes,
  value: PropTypes.arrayOf(PropTypes.object)
};

TypeaheadMultiSelect.defaultProps = {
  itemLabel: 'item',
  itemLabelPlural: 'items',
  displayValueAttribute: 'name',
  showImageInResults: false
};
