import React, { useRef, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { makeClassName } from 'utils/index';
import { CharacterLimit } from './character_limit';

import "./highlighted_textarea.sass";

export const HighlightedTextarea = (props) => {
  const [showReadonlyInput, setShowReadOnlyInput] = useState(false);
  const [readonlyText, setReadOnlyText] = useState('');

  const editableInput = useRef(null);
  const readonlyInput = useRef(null);

  useEffect(() => {
    if (props.value) {
      editableInput.current.innerHTML = props.value;
    }
  }, []);

  useEffect(() => {
    if (showReadonlyInput) {
      readonlyInput.current.innerHTML = readonlyText;
      readonlyInput.current.scrollTop = editableInput.current.scrollTop;
    }
  }, [readonlyText]);

  // To highlight the overage text we actually don't change the style of text added by the user.
  // Instead we add another div (readonlyDiv) over the one used by the user (editableDiv) and
  // this readonlyDiv has the highlighted text (inside a span).
  const handleInput = (event) => {
    const input = event.target.innerText;
    if (input.length > props.maxLength) {
      let overageText = input.substr(props.maxLength);
      overageText = `<span class="cr-highlighted-textarea__highlight">${overageText}</span>`;
      setReadOnlyText(input.substr(0, props.maxLength) + overageText);
      setShowReadOnlyInput(true);
    } else {
      setReadOnlyText("");
      setShowReadOnlyInput(false);
    }

    const text = props.allowLineBreaks ? input : input.replace(/\n/g, "");
    props.handleChange(props.name, text);
  };

  const handleKeyPress = (event) => {
    // Ignore line breaks
    if (event.key === 'Enter' && !props.allowLineBreaks) {
      event.preventDefault();
    }
  };

  const copyPlainText = (event) => {
    event.preventDefault();
    const plainText = event.clipboardData.getData('text/plain');
    const text = !props.allowLineBreaks ? plainText.replace(/\n/g, "") : plainText;

    document.execCommand('insertText', false, text);
  };

  const syncReadonlyInputScroll = (scroll) => {
    if (readonlyInput.current) {
      readonlyInput.current.scrollTop = scroll.target.scrollTop;
    }
  };

  return (
    <>
      <div
        name={props.name}
        className={makeClassName(props.className, 'cr-highlighted-textarea__editable')}
        ref={editableInput}
        contentEditable
        onInput={handleInput}
        onKeyPress={handleKeyPress}
        onScroll={syncReadonlyInputScroll}
        onPaste={copyPlainText}
      />
      {showReadonlyInput &&
        <div
          className={makeClassName(props.className, 'cr-highlighted-textarea__readonly')}
          ref={readonlyInput}
          contentEditable
        />
      }
      <CharacterLimit value={props.value} maxLength={props.maxLength}/>
    </>
  );
};

HighlightedTextarea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  allowLineBreaks: PropTypes.bool,
  maxLength: PropTypes.number.isRequired,
  handleChange: PropTypes.func,
  name: PropTypes.string
};
