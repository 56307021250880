import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import FieldGroup from "./field_group";
import { HighlightedTextarea } from './highlighted_textarea';

import "./textarea.sass";

export const Textarea = (props) => {
  /**
   * Handles update to the value of the textarea field by notifying the parent component of the new
   * value (if indeed there has been a change) and if allowLineBreaks is FALSE not allow line breaks.
   *
   * @type {function(event):void}
   */
  const handleChange = useCallback((event) => {
    const value = event.target.value;
    if (props.allowLineBreaks === false) {
      const newValue = value.replace(/\n/g, "");
      props.handleChange(props.name, newValue);
    } else {
      props.handleChange(props.name, value);
    }
  }, [props.handleChange]);

  return (
    <FieldGroup
      {...props}
      tall
      render={({ required, ...otherProps }) => (
        props.highlightExcessCharacters
          ? (
            <HighlightedTextarea
              {...otherProps}
              className="c-textarea"
              maxLength={props.maxLength}
              allowLineBreaks={props.allowLineBreaks}
              aria-required={required}
              handleChange={props.handleChange}
            />
          )
          : (
            <textarea
              {...otherProps}
              aria-required={required}
              className="c-textarea"
              onChange={handleChange}
            />
          )
      )
      }
    />
  );
};

Textarea.propTypes = {
  ...FieldGroup.propTypes,
  allowLineBreaks: PropTypes.bool,
  handleChange: PropTypes.func
};

