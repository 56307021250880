import React from 'react';
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import './character_limit.sass';

export const CharacterLimit = (props) => {
  const length = props.value ? props.value.length : "0";
  const limit = "/" + props.maxLength + " ";

  return (
    <span className="cr-form-character-limit__body">
      <span className="cr-form-character-limit__char-count">{length}</span>
      <span className="cr-form-character-limit__char-limit">
        {limit}
        <FormattedMessage
          id="settings.profile.characters_1"
          defaultMessage="characters"
        />
      </span>
    </span>
  );
};

CharacterLimit.propTypes = {
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired
};

