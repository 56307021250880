import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'form/select';
import { toggleElement } from 'utils/array';
import { TagList, Tag, TagIconButton } from 'controls/tags';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import "./multi_select_dropdown.sass";

/**
 * Component that allows multiple items to be selected from a dropdown displaying the selections
 * below the input and returning a list of those selections. Used in conjuction with Form and
 * needs to provide values provided by propsForFieldGroup or similar.
 *
 * @param {Object} props
 *   @param {{displayValue:string, SelectionValue:string}[]} props.options - The options that are
 *      passed to the dropdown
 *   @param {String[]} props.value - An array containing current selections (values should be from
 *     the list provided in options)
 *   @param {Function} props.handleChange - Function to call when selections are made or removed.
 *   @param {boolean} [props.allowDeselect=false] - if true, selecting an item from the dropdown
 *     that is currently included in the props.value will remove it
 *   @param {String} props.name - Form name passed to Select
 *   @param {String} props.label - Label value passed to Select
 *   @param {String} [props.placeholder] - optional value to use for Select box placeholder;
 *     defaults to empty string (because the Select default of "Select One" doesn't exactly apply)
 */

export const MultiSelectDropdown = (props) => {
  const currentlySelected = props.value;

  const currentlySelectedDisplay = () => {
    return props.options.filter(option => currentlySelected.includes(option.selectionValue));
  };

  const removeSelection = (value) => {
    const selections = currentlySelected.filter(selected => selected !== value);
    props.handleChange(props.name, selections);
  };

  const onSelect = (name, value) => {
    if (currentlySelected.includes(value) && !props.allowDeselect) { return; }

    const selections = toggleElement(currentlySelected, value);
    props.handleChange(name, selections);
  };

  return (
    <div className="cr-multi-select-dropdown__container">
      {/* https://acclaim.atlassian.net/browse/AC-7921 to remove the dependence on Select
          see https://github.com/YourAcclaim/acclaim-server/pull/3731 for discussion */}
      <Select
        {...props}
        skipSelectionDisplay
        ignoreDefaultValidations
        value=""
        handleChange={onSelect}
      />
      <TagList>
        {currentlySelectedDisplay().map(item =>
          <Tag
            ui="default"
            key={item.selectionValue}
            icon={
              <TagIconButton
                aria-label="Remove"
                icon={faTimes}
                onClick={() => removeSelection(item.selectionValue)}
              />
            }
          >
            {item.displayValue}
          </Tag>
        )}
      </TagList>
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  allowDeselect: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    displayValue: PropTypes.string.isRequired,
    selectionValue: PropTypes.string.isRequired
  })),
  value: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string
};

MultiSelectDropdown.defaultProps = {
  value: [],
  options: [],
  allowDeselect: false,
  placeholder: ''
};
