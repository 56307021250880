import React, { useCallback, useRef, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import * as objectUtils from 'utils/object';
import { useComponentTracking } from 'app_utils/tracking';
import { useSearchOnType } from 'controls/search/search_hooks';
import { useComboboxHandlers } from 'form/form_hooks';
import TextField from './text_field';
import FieldGroup from './field_group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { TypeaheadSuggestions } from "./typeahead_suggestions";

import './typeahead_text_field.sass';

export const TypeaheadTextField = (props) => {
  const trackState = useComponentTracking({
    ...props.trackingDetail,
    field_name: props.name
  });

  const lastCompletedValue = useRef(props.value);

  const performSearch = useCallback((query) => {
    trackState.track('typeahead.search', { query });
    props.searchAction(query);
  }, [props.searchAction]);

  const [expanded, setExpanded] = useState(false);
  const searchState = useSearchOnType(
    props.searchActionState,
    performSearch,
    props.value,
    { localMatches: props.localMatches, active: expanded, searchOnEmpty: props.searchOnEmpty }
  );
  const textFieldProps = objectUtils.except(props,
    ['displayValueAttribute', 'iconStyle', 'localMatches', 'searchAction', 'searchActionState']
  );

  // We need to pass a ref to the TextField so that we can focus and blur it
  const typeaheadInputRef = useRef();

  // Keep track of current query and options for stat tracking
  const snapshotRef = useRef();
  snapshotRef.current = {
    query: props.value,
    options: searchState.results?.map((result) => result.name)
  };

  const onSelect = useCallback((item) => {
    if (item) {
      const itemName = props.displayValueAttribute ? item[props.displayValueAttribute] : item.name;
      lastCompletedValue.current = itemName;
      trackState.track(
        'typeahead.selection',
        { ...snapshotRef.current, selected: itemName, input_manner: 'suggestion' }
      );
      if (props.handleSelect) {
        props.handleSelect(item);
      } else {
        props.handleChange(props.name, itemName);
      }
    }
    typeaheadInputRef.current.blur();
  }, [props.handleChange, props.name, props.handleSelect, trackState.track]);

  const comboboxHandlers = useComboboxHandlers(searchState.results, onSelect);

  useEffect(() => { setExpanded(comboboxHandlers.expanded); }, [comboboxHandlers.expanded]);

  const handleFocus = useCallback(() => {
    props.handleFocus && props.handleFocus();
    comboboxHandlers.handleFocus();
  }, [props.handleFocus, comboboxHandlers.handleFocus]);

  const handleBlur = useCallback((event) => {
    props.handleBlur && props.handleBlur();
    comboboxHandlers.handleBlur(event);

    // Wait for the input field to be updated before tracking stat
    // 100ms is enough time for the value of the field to be updated
    // after a combobox selection is made and the field is blurred
    const snapshot = { ...snapshotRef.current };
    setTimeout(() => {
      const value = snapshotRef.current.query;
      if (value !== lastCompletedValue.current) {
        trackState.track(
          'typeahead.selection',
          { ...snapshot, selected: value, input_manner: 'manual' }
        );
        lastCompletedValue.current = value;
      }
    }, 100);
  }, [props.handleBlur, comboboxHandlers.handleBlur]);

  let suggestions = null;

  if (searchState.results?.length > 0 && comboboxHandlers.expanded) {
    suggestions = <TypeaheadSuggestions
      comboboxHandlers={comboboxHandlers}
      showImageInResults={props.showImageInResults}
      suggestions={searchState.results}
      displayValueAttribute={props.displayValueAttribute}
    />;
  }

  let icon;
  if (props.iconStyle === 'search') {
    icon = faSearch;
  } else if (props.iconStyle === 'arrows') {
    // We want to use arrows here, BUT we should only show the arrows if there are actually results
    // to display. That is to say, if searchState.results.length == 0, meaning we wouldnt show
    // results anyways, it makes no sense to include the icon
    if (searchState.results?.length > 0) {
      icon = comboboxHandlers.expanded ? faChevronUp : faChevronDown;
    }
  }
  return (
    <div className="cr-typeahead-text-field" {...comboboxHandlers.comboboxProps} tabIndex={-1}>
      <TextField
        {...textFieldProps}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        onKeyDown={comboboxHandlers.handleKeyDown}
        showIcon
        inputRef={typeaheadInputRef}
        {...comboboxHandlers.textboxProps}
        autoComplete="off"
      />
      {icon && <FontAwesomeIcon icon={icon} className="cr-typeahead__icon"/>}
      {suggestions}
    </div>
  );
};

// This takes in all of FieldGroup.propTypes, but the main ones developers should include are:
// value: PropTypes.string
// label: PropTypes.string
// handleChange: PropTypes.func
// enclosed: PropTypes.boolean (For styling)
TypeaheadTextField.propTypes = {
  displayValueAttribute: PropTypes.string,
  iconStyle: PropTypes.oneOf(['arrows', 'none', 'search']),
  localMatches: PropTypes.func,
  searchAction: PropTypes.func,
  searchActionState: PropTypes.object,
  ...FieldGroup.propTypes
};
